var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "c-building__flat",
      class: {
        "is-active": _vm.property.isSelected,
        "is-not-filtred": !_vm.property.isFiltred
      },
      style: [
        _vm.property.status == "Свободна"
          ? { cursor: "pointer" }
          : { cursor: "not-allowed" }
      ],
      attrs: { href: "#" },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.onViewApartment.apply(null, arguments)
        }
      }
    },
    [
      _vm.property.isFiltred
        ? _c("div", { staticClass: "c-building__flat-type" }, [
            _vm.property.isFiltred
              ? _c(
                  "div",
                  {
                    style: {
                      "border-color": _vm.color,
                      "background-color": _vm.color,
                      "border-width": "9px",
                      "border-style": "inset",
                      width: "100%",
                      height: "100%",
                      "align-items": "center",
                      display: "flex",
                      "justify-content": "center",
                      "border-radius": "13px"
                    }
                  },
                  [_vm._v("\n      " + _vm._s(_vm.KKS) + "\n    ")]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "c-building__flat-inner pa-2",
          style: { "min-height": "240px" }
        },
        [
          _vm.property.isFiltred
            ? _c("div", [
                _c("div", { staticClass: "d-flex align-center" }, [
                  _c(
                    "div",
                    {
                      staticClass: "c-building__flat-type",
                      style: { "background-color": _vm.color }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.property["rooms"]) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-2 has-opacity-65" }, [
                    _vm._v(_vm._s(_vm.property["type_object"]))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ml-auto has-opacity-65" }, [
                  _vm._v(_vm._s(_vm.property.name))
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "font-weight-bold text-body-1 mt-2" },
                  [_vm._v(_vm._s(_vm._f("num")(_vm.property.cost)) + " ₽")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "has-opacity-65 mt-1" }, [
                  _vm._v(
                    _vm._s(_vm._f("num")(_vm.property["area"])) +
                      " м² - " +
                      _vm._s(_vm._f("num")(_vm.property.priceM2)) +
                      " ₽/м²"
                  )
                ]),
                _vm._v(" "),
                _vm.property.status == "Свободна"
                  ? _c(
                      "div",
                      {
                        staticClass: "mt-1 has-opacity-65",
                        style: { "background-color": _vm.color }
                      },
                      [
                        _vm._v(
                          "Статус квартиры - " + _vm._s(_vm.property.status)
                        )
                      ]
                    )
                  : _c("div", { staticClass: "mt-1 has-opacity-65" }, [
                      _vm._v("Статус квартиры - " + _vm._s(_vm.property.status))
                    ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-10" }, [
                  _c(
                    "a",
                    {
                      staticClass: "c-image-property image-link",
                      on: { click: _vm.onOpenImage }
                    },
                    [
                      typeof _vm.property.images !== "undefined" &&
                      typeof _vm.property.images[0] !== "undefined" &&
                      typeof _vm.property.images[0].src !== "undefined"
                        ? _c("img", {
                            attrs: { src: _vm.property.images[0].src, alt: "" }
                          })
                        : _vm._e()
                    ]
                  )
                ])
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }