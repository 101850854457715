var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "c-building__flat",
      class: {
        "is-active": _vm.property.isSelected,
        "is-not-filtred": !_vm.property.isFiltred
      },
      attrs: { href: "#", id: _vm.property.id },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.onViewApartment.apply(null, arguments)
        }
      }
    },
    [
      _c("div", { staticClass: "c-building__flat-type" }, [
        _c(
          "div",
          {
            style: {
              "border-color": _vm.color,
              "background-color": _vm.color,
              "border-width": "9px",
              "border-style": "inset",
              width: "100%",
              height: "100%",
              "align-items": "center",
              display: "flex",
              "justify-content": "center",
              "border-radius": "13px"
            }
          },
          [_vm._v("\n      " + _vm._s(_vm.KKS) + "\n    ")]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "c-building__flat-inner pa-2",
          style: { "min-height": "240px" }
        },
        [
          _c("div", [
            _c("div", { staticClass: "d-flex align-center" }, [
              _c(
                "div",
                {
                  staticClass: "c-building__flat-type",
                  style: { "background-color": _vm.color }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.property["rooms"]) +
                      "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ml-2 has-opacity-65" }, [
                _vm._v(_vm._s(_vm.KKS))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ml-auto has-opacity-65" }, [
                _vm._v("№" + _vm._s(_vm.property.number))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "font-weight-bold text-body-1 mt-2" }, [
              _vm._v(_vm._s(_vm._f("num")(_vm.property.cost)) + " ₽")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "has-opacity-65 mt-1" }, [
              _vm._v(
                _vm._s(_vm._f("num")(_vm.property["area"])) +
                  " м² - " +
                  _vm._s(_vm._f("num")(_vm.property.priceM2)) +
                  " ₽/м²"
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-10" }, [
              _c(
                "a",
                {
                  staticClass: "c-image-property image-link",
                  on: { click: _vm.onOpenImage }
                },
                [
                  _c("img", {
                    attrs: { src: _vm.property.images[0].src, alt: "" }
                  })
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "trash_building_flat",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                version: "1.0",
                width: "20",
                height: "26",
                viewBox: "0 0 1200.000000 1200.000000",
                preserveAspectRatio: "xMidYMid meet"
              },
              on: {
                click: function($event) {
                  return _vm.removeFromFav(
                    _vm.property ? _vm.property.id : null
                  )
                }
              }
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    transform:
                      "translate(0.000000,1200.000000) scale(0.100000,-0.100000)",
                    fill: "#000000",
                    stroke: "none"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M4815 11295 c-211 -39 -475 -195 -605 -359 -83 -103 -114 -168 -359 -754 l-243 -582 -1067 -2 -1066 -3 -45 -25 c-49 -28 -84 -73 -100 -129 -13 -48 -13 -505 1 -554 13 -49 63 -105 114 -128 36 -17 73 -19 387 -19 l348 0 3 -3302 3 -3303 21 -95 c40 -178 100 -337 170 -454 170 -283 388 -461 652 -535 l96 -26 2910 0 2910 0 96 26 c166 46 293 122 425 254 225 225 373 533 413 860 8 63 11 1051 11 3333 l0 3242 344 0 c236 0 354 4 377 12 54 18 87 48 114 102 25 49 25 53 25 305 0 145 -5 272 -10 293 -14 49 -52 93 -104 121 l-41 22 -1065 3 -1065 2 -139 333 c-402 959 -389 933 -525 1067 -130 129 -310 235 -479 283 l-77 22 -1180 2 c-931 1 -1195 -1 -1250 -12z m2333 -859 c15 -8 37 -25 48 -38 17 -18 334 -771 334 -792 0 -3 -673 -6 -1496 -6 -1193 0 -1495 3 -1491 13 121 296 323 767 335 781 10 10 31 27 47 37 29 18 76 19 1113 19 911 0 1087 -2 1110 -14z m1892 -4868 c0 -1940 -4 -3208 -10 -3263 -16 -150 -76 -299 -153 -380 l-44 -45 -2798 0 -2799 0 -42 45 c-43 47 -99 151 -130 245 -18 53 -19 171 -22 3313 l-2 3257 3000 0 3000 0 0 -3172z"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M4020 7434 c-19 -8 -50 -29 -68 -47 -66 -64 -62 70 -62 -2077 0 -2172 -5 -2016 69 -2083 61 -55 85 -59 388 -55 l271 3 43 30 c23 17 52 48 63 70 l21 40 0 1995 0 1995 -21 40 c-11 22 -40 54 -63 70 l-43 30 -281 2 c-236 2 -288 0 -317 -13z"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M5720 7428 c-26 -14 -57 -41 -75 -66 l-30 -44 0 -2008 0 -2008 30 -44 c18 -25 49 -52 75 -65 43 -22 54 -23 312 -23 220 0 275 3 306 15 47 20 79 50 103 97 18 36 19 91 19 2028 0 1937 -1 1992 -19 2028 -24 47 -56 77 -103 97 -31 12 -86 15 -306 15 -258 0 -269 -1 -312 -22z"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M7439 7430 c-48 -25 -96 -81 -108 -127 -15 -53 -15 -3933 0 -3986 13 -47 60 -102 110 -128 35 -17 61 -19 312 -19 267 0 275 1 318 23 29 16 54 40 74 72 l30 48 0 1997 0 1997 -30 48 c-20 32 -45 56 -74 72 -43 23 -50 23 -320 23 -252 -1 -279 -3 -312 -20z"
                    }
                  })
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }