var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "c-building__flat",
      class: {
        "is-active": _vm.property.isSelected,
        "is-not-filtred": !_vm.property.isFiltred
      },
      attrs: { href: "#", id: _vm.property.id },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.onViewApartment.apply(null, arguments)
        }
      }
    },
    [
      _c("div", { staticClass: "c-building__flat-type" }, [
        _c(
          "div",
          {
            style: {
              "border-color": _vm.color,
              "background-color": _vm.color,
              "border-width": "9px",
              "border-style": "inset",
              width: "100%",
              height: "100%",
              "align-items": "center",
              display: "flex",
              "justify-content": "center",
              "border-radius": "13px"
            }
          },
          [_vm._v("\n      " + _vm._s(_vm.KKS) + "\n    ")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-building__flat-inner pa-2" }, [
        _c("div", [
          _c("div", { staticClass: "d-flex" }, [
            _c(
              "div",
              {
                staticClass: "ml-2 has-opacity-65",
                staticStyle: { "font-size": "small" }
              },
              [_vm._v(_vm._s(_vm.KKS))]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "has-opacity-65 mt-1" }, [
            _vm._v(_vm._s(_vm._f("num")(_vm.property["area"])) + " м² - "),
            _c("br"),
            _vm._v(" " + _vm._s(_vm._f("num")(_vm.property.priceM2)) + " ₽/м²")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-10" }, [
            _c(
              "a",
              {
                staticClass: "c-image-property image-link",
                on: { click: _vm.onOpenImage }
              },
              [
                _c("img", {
                  attrs: { src: _vm.property.images[0].src, alt: "" }
                })
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }