var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.closeLightBox.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "transition",
        {
          attrs: { mode: "out-in", name: "vue-lb-content-transition" },
          on: {
            afterEnter: _vm.enableImageTransition,
            beforeLeave: _vm.disableImageTransition
          }
        },
        [
          _vm.media
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.lightBoxOn,
                      expression: "lightBoxOn"
                    }
                  ],
                  ref: "container",
                  staticClass: "vue-lb-container"
                },
                [
                  _c("div", { staticClass: "vue-lb-content" }, [
                    _c("div", { staticClass: "vue-lb-header" }, [
                      _c("span"),
                      _vm._v(" "),
                      _vm.closable
                        ? _c(
                            "button",
                            {
                              staticClass: "vue-lb-button-close",
                              attrs: { type: "button", title: _vm.closeText }
                            },
                            [
                              _vm._t("close", function() {
                                return [_c("CloseIcon")]
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "vue-lb-figure",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                          }
                        }
                      },
                      [
                        _c(
                          "transition",
                          {
                            attrs: {
                              mode: "out-in",
                              name: _vm.modalImageTransitionName
                            }
                          },
                          [
                            !_vm.media[_vm.select].type
                              ? _c("img", {
                                  key: _vm.media[_vm.select].src,
                                  staticClass: "vue-lb-modal-image",
                                  attrs: {
                                    src: _vm.media[_vm.select].src,
                                    srcset: _vm.media[_vm.select].srcset || "",
                                    alt: _vm.media[_vm.select].caption
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.nextImage()
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.media[_vm.select].type == "video"
                              ? _c(
                                  "video",
                                  {
                                    key: _vm.media[_vm.select].sources[0].src,
                                    ref: "video",
                                    attrs: {
                                      width: _vm.media[_vm.select].width,
                                      height: _vm.media[_vm.select].height,
                                      autoplay: _vm.media[_vm.select].autoplay,
                                      controls: ""
                                    }
                                  },
                                  _vm._l(
                                    _vm.media[_vm.select].sources,
                                    function(source) {
                                      return _c("source", {
                                        key: source.src,
                                        attrs: {
                                          src: source.src,
                                          type: source.type
                                        }
                                      })
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.media[_vm.select].type == "3d"
                              ? _c("div", { staticClass: "iframe-loading" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "i3dTourModalButton",
                                      on: { click: _vm.setModal }
                                    },
                                    [_vm._v("открыть во весь экран")]
                                  ),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.modal_1,
                                            expression: "modal_1"
                                          }
                                        ],
                                        staticClass: "c-modal"
                                      },
                                      [_c("span", [_vm._v("modal_1")])]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.modal_1,
                                            expression: "modal_1"
                                          }
                                        ],
                                        staticClass: "bg",
                                        attrs: { id: "bg_3d_tout" },
                                        on: { click: _vm.setModal }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "close",
                                            on: { click: _vm.setModal }
                                          },
                                          [
                                            _c("span", {
                                              on: { click: _vm.setModal }
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              on: { click: _vm.setModal }
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              on: { click: _vm.setModal }
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              on: { click: _vm.setModal }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "svg",
                                              {
                                                staticClass: "circle",
                                                attrs: { viewBox: "0 0 36 36" },
                                                on: { click: _vm.setModal }
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    "stroke-dasharray":
                                                      "100, 100",
                                                    d:
                                                      "M18 2.0845\n        a 15.9155 15.9155 0 0 1 0 31.831\n        a 15.9155 15.9155 0 0 1 0 -31.831"
                                                  },
                                                  on: { click: _vm.setModal }
                                                })
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "iframe",
                                          {
                                            staticClass: "ifarameFullScreen",
                                            attrs: {
                                              src: _vm.media[_vm.select].src,
                                              width: "90%%",
                                              height: "90%"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                      Пожалуйста подождите, 3д модель загружается на ваше устройство\n                    "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "vue-lb-footer" }, [
                          _c("div", { staticClass: "vue-lb-footer-info" }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showFooterCount && false,
                                  expression: "showFooterCount && false"
                                }
                              ],
                              staticClass: "vue-lb-footer-count"
                            },
                            [
                              _vm._t(
                                "footer",
                                function() {
                                  return [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.select + 1) +
                                        " / " +
                                        _vm._s(_vm.media.length) +
                                        "\n                "
                                    )
                                  ]
                                },
                                {
                                  current: _vm.select + 1,
                                  total: _vm.media.length
                                }
                              )
                            ],
                            2
                          )
                        ])
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "vue-lb-thumbnail-wrapper" }, [
                    _vm.showThumbs
                      ? _c(
                          "div",
                          { staticClass: "vue-lb-thumbnail" },
                          [
                            _vm.media.length > 1
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "vue-lb-thumbnail-arrow vue-lb-thumbnail-left",
                                    attrs: {
                                      type: "button",
                                      title: _vm.previousThumbText
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.previousImage()
                                      }
                                    }
                                  },
                                  [
                                    _vm._t("previousThumb", function() {
                                      return [_c("LeftArrowIcon")]
                                    })
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.imagesThumb, function(image, index) {
                              return _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        index >= _vm.thumbIndex.begin &&
                                        index <= _vm.thumbIndex.end,
                                      expression:
                                        "index >= thumbIndex.begin && index <= thumbIndex.end"
                                    }
                                  ],
                                  key:
                                    typeof image.src === "string"
                                      ? `${image.src}${index}`
                                      : index,
                                  class:
                                    "vue-lb-info_gal" +
                                    (_vm.select === index ? "-active" : ""),
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.showImage(index)
                                    }
                                  }
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.media[index].caption
                                      )
                                    }
                                  })
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _vm.media.length > 1
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "vue-lb-thumbnail-arrow vue-lb-thumbnail-right",
                                    attrs: {
                                      type: "button",
                                      title: _vm.nextThumbText
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.nextImage()
                                      }
                                    }
                                  },
                                  [
                                    _vm._t("nextThumb", function() {
                                      return [_c("RightArrowIcon")]
                                    })
                                  ],
                                  2
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.media.length > 1
                    ? _c(
                        "button",
                        {
                          staticClass: "vue-lb-arrow vue-lb-left",
                          attrs: { type: "button", title: _vm.previousText },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.previousImage()
                            }
                          }
                        },
                        [
                          _vm._t("previous", function() {
                            return [_c("LeftArrowIcon")]
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.media.length > 1
                    ? _c(
                        "button",
                        {
                          staticClass: "vue-lb-arrow vue-lb-right",
                          attrs: { type: "button", title: _vm.nextText },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.nextImage()
                            }
                          }
                        },
                        [
                          _vm._t("next", function() {
                            return [_c("RightArrowIcon")]
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }