var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.checkViewMode()
    ? _c(
        "aside",
        {
          staticClass: "overflow-hidden contentFlat",
          class: _vm.mq.mdAndUp ? _vm.asideClasses : ["full-height"]
        },
        [
          _c("div", { staticClass: "flat" }, [
            _c(
              "div",
              { staticClass: "flat__media" },
              [
                _c("light-box", {
                  attrs: {
                    media: this.matched,
                    closable: false,
                    showCaption: true,
                    showThumbs: true
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "vue-lb-info_bottom" }, [
                  _c(
                    "div",
                    {
                      staticClass: "vue-lb-info_Etag",
                      on: { click: _vm.showHide }
                    },
                    [_c("div", [_vm._v("Наличие по этажам")])]
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "flat__info" }, [
              _vm.property
                ? _c("div", { staticClass: "d-flex flex-column full-height" }, [
                    _c(
                      "div",
                      {
                        staticClass: "panelRight full-height pb-10",
                        class: {
                          "pt-5": _vm.mq.mdAndDown,
                          "pt-10": _vm.mq.mdAndUp
                        }
                      },
                      [
                        _c("p", { attrs: { id: "tooltip-text" } }, [
                          _vm._v("Добавлено в избранное")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tooltip-container",
                            staticStyle: {
                              position: "absolute",
                              right: "23px",
                              cursor: "pointer",
                              top: "45px",
                              width: "40px",
                              height: "40px"
                            }
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  "/*right": "23px",
                                  "*/\n    cursor": "pointer",
                                  "/*top": "12px"
                                },
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  version: "1.0",
                                  width: "36",
                                  height: "42",
                                  viewBox: "0 0 512.000000 512.000000",
                                  preserveAspectRatio: "xMidYMid meet",
                                  title: "Добавить в избранное",
                                  id: "tooltip-button"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.saveToFav(
                                      _vm.property ? _vm.property.id : null
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      transform:
                                        "translate(0.000000,512.000000) scale(0.100000,-0.100000)",
                                      fill: _vm.getColor,
                                      stroke: "orange",
                                      "stroke-width": "240",
                                      "stroke-dasharray": "5,5",
                                      "stroke-linecap": "round"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M2496 4968 c-28 -29 -61 -126 -311 -895 l-279 -863 -919 0 c-611 0 -925 -3 -938 -10 -23 -13 -49 -61 -49 -93 0 -13 9 -36 20 -50 11 -14 346 -262 745 -552 398 -289 727 -530 730 -535 3 -4 -121 -396 -275 -870 -154 -474 -280 -873 -280 -887 0 -36 60 -93 98 -93 37 0 -26 -43 812 565 l710 516 710 -516 c838 -608 775 -565 812 -565 37 0 98 57 98 92 0 12 -126 414 -281 891 -155 477 -280 869 -278 871 2 1 331 240 731 530 400 290 737 539 748 553 11 14 20 37 20 50 0 32 -26 80 -49 93 -13 7 -327 10 -938 10 l-919 0 -279 863 c-249 767 -283 866 -311 895 -23 23 -41 32 -64 32 -23 0 -41 -9 -64 -32z"
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: {
                              "px-5": _vm.mq.mdAndDown,
                              "px-10": _vm.mq.mdAndUp
                            }
                          },
                          [
                            _c("div", { staticClass: "mb-5" }, [
                              _c("div", { staticClass: "title-copy1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getName(
                                      _vm.property.type_apartment,
                                      _vm.property.kitchen_living
                                    )
                                  ) +
                                    " " +
                                    _vm._s(_vm.property.area) +
                                    " м²"
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "mb-10" }),
                            _vm._v(" "),
                            _vm.lenDiscounts()
                              ? _c("div", { staticClass: "mb-5" }, [
                                  _c("div", { staticClass: "title-copy2" }, [
                                    _vm._v(_vm._s(_vm.getCost()) + " ₽")
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "title-copy2_1" }, [
                                    _vm._v(
                                      _vm._s(_vm.numberWithSpacesG()) + " ₽"
                                    )
                                  ])
                                ])
                              : _c("div", { staticClass: "mb-5" }, [
                                  _c("div", { staticClass: "title-copy2" }, [
                                    _vm._v(
                                      _vm._s(_vm.numberWithSpacesG()) + " ₽"
                                    )
                                  ])
                                ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "mb-10" }, [
                              _c("div", { staticClass: "c-list text-body-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "c-list__item d-flex justify-space-between"
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        'Скидки за наличный расчет, цена выше указана на "white box"\n                  '
                                      ),
                                      _vm.whitePriceBox
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                                display: "inline",
                                                "text-decoration": "underline"
                                              },
                                              on: {
                                                click:
                                                  _vm.setModalDialogBySelectFaltWhiteBox
                                              }
                                            },
                                            [
                                              _c("br"),
                                              _vm._v(
                                                "Цена с полной чистовой отделкой  " +
                                                  _vm._s(_vm.whitePriceBox) +
                                                  "  "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                                display: "inline",
                                                "text-decoration": "underline"
                                              },
                                              on: {
                                                click:
                                                  _vm.setModalDialogBySelectFaltWhiteBox
                                              }
                                            },
                                            [
                                              _c("br"),
                                              _vm._v(
                                                "Посмотреть планировки с полной чистовой отделкой  "
                                              )
                                            ]
                                          )
                                    ])
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "flat__callback",
                                on: {
                                  click: function($event) {
                                    return _vm.setModalDialog(
                                      true,
                                      _vm.property
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "buttonCopy2",
                                    staticStyle: {
                                      "background-color": "#e47554"
                                    }
                                  },
                                  [_vm._v("\n              Забронировать\n")]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "text-copy4" }, [
                              _vm._v(
                                "\n            Характеристики квартиры\n          "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "mb-10" }, [
                              _c(
                                "div",
                                { staticClass: "c-list text-body-2" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "c-list__item d-flex justify-space-between"
                                    },
                                    [
                                      _c("div", [_vm._v("Статус")]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.property.status))
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.lenDiscounts()
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "c-list__item d-flex justify-space-between"
                                        },
                                        [
                                          _c("div", [_vm._v("Цена, м²")]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(_vm.getCostM2()) + " ₽  "
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "text-decoration":
                                                    "line-through"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("num")(
                                                      _vm.property.priceM2
                                                    )
                                                  ) + " ₽"
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "c-list__item d-flex justify-space-between"
                                        },
                                        [
                                          _c("div", [_vm._v("Цена, м²")]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("num")(
                                                  _vm.property.priceM2
                                                )
                                              ) + " ₽"
                                            )
                                          ])
                                        ]
                                      ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "c-list__item d-flex justify-space-between"
                                    },
                                    [
                                      _c("div", [_vm._v("Площадь")]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(_vm.property["area"]) + " м²"
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  !["Прочее", "Гараж/Паркоместо"].includes(
                                    _vm.property["type_object"]
                                  )
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "c-list__item d-flex justify-space-between"
                                        },
                                        [
                                          _c("div", [_vm._v("Площадь кухни")]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.property["area_kitchen"]
                                              ) + " м²"
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "c-list__item d-flex justify-space-between"
                                    },
                                    [
                                      _c("div", [_vm._v("Этаж")]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.property.floor))
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.property["Balconies"], function(
                                    b,
                                    i
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: `p${i}`,
                                        staticClass:
                                          "c-list__item d-flex justify-space-between"
                                      },
                                      [
                                        _c("div", [_vm._v(_vm._s(b["type"]))]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(b["square_meters"]) + " м²"
                                          )
                                        ])
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                            ]),
                            _vm._v(" "),
                            _vm.property.description
                              ? _c("div", { staticClass: "mb-10" }, [
                                  _c(
                                    "div",
                                    { staticClass: "text-body-2 mb-1" },
                                    [_vm._v("Описание")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-body-1 font-weight-500 mb-5"
                                    },
                                    [_vm._v(_vm._s(_vm.title))]
                                  ),
                                  _vm._v(" "),
                                  _vm.property.description
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "c-collapsible text-body-1",
                                          class: {
                                            "is-open":
                                              _vm.descriptionLenght < 1220 ||
                                              _vm.descriptioOpened
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              ref: "description",
                                              staticClass:
                                                "c-collapsible__content",
                                              style: _vm.collapseStyle
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.property.description)
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.descriptionLenght > 1220
                                            ? _c("div", [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "c-collapsible__trigger",
                                                    attrs: { href: "#" },
                                                    on: {
                                                      click:
                                                        _vm.onToggleCollapse
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.collapseTriggerText
                                                      )
                                                    )
                                                  ]
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "flat__callback" }, [
                _vm.webShareApiSupported
                  ? _c(
                      "button",
                      {
                        staticClass: "buttonCopy2",
                        staticStyle: { position: "relative", left: "20px" },
                        on: { click: _vm.shareViaWebShare }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticStyle: { position: "absolute", left: "20px" },
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "24",
                              height: "24",
                              viewBox: "0 0 24 24",
                              fill: "none"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                "fill-rule": "evenodd",
                                "clip-rule": "evenodd",
                                d:
                                  "M13.803 5.33333C13.803 3.49238 15.3022 2 17.1515 2C19.0008 2 20.5 3.49238 20.5 5.33333C20.5 7.17428 19.0008 8.66667 17.1515 8.66667C16.2177 8.66667 15.3738 8.28596 14.7671 7.67347L10.1317 10.8295C10.1745 11.0425 10.197 11.2625 10.197 11.4872C10.197 11.9322 10.109 12.3576 9.94959 12.7464L15.0323 16.0858C15.6092 15.6161 16.3473 15.3333 17.1515 15.3333C19.0008 15.3333 20.5 16.8257 20.5 18.6667C20.5 20.5076 19.0008 22 17.1515 22C15.3022 22 13.803 20.5076 13.803 18.6667C13.803 18.1845 13.9062 17.7255 14.0917 17.3111L9.05007 13.9987C8.46196 14.5098 7.6916 14.8205 6.84848 14.8205C4.99917 14.8205 3.5 13.3281 3.5 11.4872C3.5 9.64623 4.99917 8.15385 6.84848 8.15385C7.9119 8.15385 8.85853 8.64725 9.47145 9.41518L13.9639 6.35642C13.8594 6.03359 13.803 5.6896 13.803 5.33333Z",
                                fill: "#fff"
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "buttonCopy2span" }, [
                          _vm._v("Поделиться")
                        ])
                      ]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "buttonCopy2",
                        staticStyle: { position: "relative", left: "20px" },
                        on: { click: _vm.saveToClipboard }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticStyle: { position: "absolute", left: "20px" },
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "24",
                              height: "24",
                              viewBox: "0 0 24 24",
                              fill: "none"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                "fill-rule": "evenodd",
                                "clip-rule": "evenodd",
                                d:
                                  "M13.803 5.33333C13.803 3.49238 15.3022 2 17.1515 2C19.0008 2 20.5 3.49238 20.5 5.33333C20.5 7.17428 19.0008 8.66667 17.1515 8.66667C16.2177 8.66667 15.3738 8.28596 14.7671 7.67347L10.1317 10.8295C10.1745 11.0425 10.197 11.2625 10.197 11.4872C10.197 11.9322 10.109 12.3576 9.94959 12.7464L15.0323 16.0858C15.6092 15.6161 16.3473 15.3333 17.1515 15.3333C19.0008 15.3333 20.5 16.8257 20.5 18.6667C20.5 20.5076 19.0008 22 17.1515 22C15.3022 22 13.803 20.5076 13.803 18.6667C13.803 18.1845 13.9062 17.7255 14.0917 17.3111L9.05007 13.9987C8.46196 14.5098 7.6916 14.8205 6.84848 14.8205C4.99917 14.8205 3.5 13.3281 3.5 11.4872C3.5 9.64623 4.99917 8.15385 6.84848 8.15385C7.9119 8.15385 8.85853 8.64725 9.47145 9.41518L13.9639 6.35642C13.8594 6.03359 13.803 5.6896 13.803 5.33333Z",
                                fill: "#fff"
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "buttonCopy2span" }, [
                          _vm._v("Поделиться")
                        ])
                      ]
                    ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "buttonCopy2",
                    staticStyle: { position: "relative", left: "21px" },
                    attrs: {
                      type: "button",
                      "data-callback": "true",
                      "data-href": "zabronirovat-kvartiru",
                      "aria-label": "Задать вопрос",
                      "data-init": "true"
                    },
                    on: { click: _vm.setModalDialog }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          position: "absolute",
                          left: "15px",
                          "font-size": "20px",
                          "font-weight": "900",
                          "font-style": "normal",
                          "font-stretch": "ultra-condensed"
                        },
                        attrs: { "data-v-c4a012b6": "" }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              "data-v-c4a012b6": "",
                              fill: "#fff",
                              height: "25px",
                              width: "25px",
                              version: "1.1",
                              id: "Capa_1",
                              xmlns: "http://www.w3.org/2000/svg",
                              "xmlns:xlink": "http://www.w3.org/1999/xlink",
                              viewBox: "0 0 60 60",
                              "xml:space": "preserve"
                            }
                          },
                          [
                            _c("g", { attrs: { "data-v-c4a012b6": "" } }, [
                              _c("path", {
                                attrs: {
                                  "data-v-c4a012b6": "",
                                  d:
                                    "M44.348,12.793H2.652C1.189,12.793,0,13.982,0,15.445v43.762l9.414-9.414h34.934c1.463,0,2.652-1.19,2.652-2.653V15.445C47,13.982,45.811,12.793,44.348,12.793z M10,35.777c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S12.206,35.777,10,35.777z M23,35.777c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S25.206,35.777,23,35.777z M36,35.777c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S38.206,35.777,36,35.777z"
                                }
                              }),
                              _vm._v(" "),
                              _c("path", {
                                attrs: {
                                  "data-v-c4a012b6": "",
                                  d:
                                    "M57.348,0.793H12.652C11.189,0.793,10,1.982,10,3.445v7.348h34.348c2.565,0,4.652,2.087,4.652,4.652v25.332h11V3.445C60,1.982,58.811,0.793,57.348,0.793z"
                                }
                              })
                            ])
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "buttonCopy2span" }, [
                      _vm._v("Задать вопрос")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "buttonCopy2",
                    staticStyle: { position: "relative", left: "21px" },
                    attrs: {
                      type: "button",
                      value: _vm.property ? _vm.property.id : null,
                      "data-callback": "true",
                      "data-href": "zabronirovat-kvartiru",
                      "aria-label": "В избранное",
                      "data-init": "true"
                    },
                    on: { click: _vm.setModal }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticStyle: { position: "absolute", left: "20px" },
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          version: "1.0",
                          width: "19",
                          height: "24",
                          viewBox: "0 0 512.000000 512.000000",
                          preserveAspectRatio: "xMidYMid meet"
                        }
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              transform:
                                "translate(0.000000,512.000000) scale(0.100000,-0.100000)",
                              fill: "#fff",
                              stroke: "none"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M2496 4968 c-28 -29 -61 -126 -311 -895 l-279 -863 -919 0 c-611 0 -925 -3 -938 -10 -23 -13 -49 -61 -49 -93 0 -13 9 -36 20 -50 11 -14 346 -262 745 -552 398 -289 727 -530 730 -535 3 -4 -121 -396 -275 -870 -154 -474 -280 -873 -280 -887 0 -36 60 -93 98 -93 37 0 -26 -43 812 565 l710 516 710 -516 c838 -608 775 -565 812 -565 37 0 98 57 98 92 0 12 -126 414 -281 891 -155 477 -280 869 -278 871 2 1 331 240 731 530 400 290 737 539 748 553 11 14 20 37 20 50 0 32 -26 80 -49 93 -13 7 -327 10 -938 10 l-919 0 -279 863 c-249 767 -283 866 -311 895 -23 23 -41 32 -64 32 -23 0 -41 -9 -64 -32z"
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "buttonCopy2span" }, [
                      _vm._v("В избранное")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "buttonCopy2",
                    staticStyle: { position: "relative", left: "21px" },
                    attrs: {
                      type: "button",
                      "data-callback": "true",
                      "data-href": "chosePlan",
                      "aria-label": "Выбрать планировку",
                      "data-init": "true"
                    },
                    on: { click: _vm.setModalDialogBySelectFalt }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          position: "absolute",
                          left: "26px",
                          "font-size": "20px",
                          "font-weight": "900",
                          "font-style": "normal",
                          "font-stretch": "ultra-condensed"
                        }
                      },
                      [_vm._v("||")]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "buttonCopy2span" }, [
                      _vm._v("Вce планировки")
                    ])
                  ]
                ),
                _vm._v(" "),
                false && !_vm.isMobile()
                  ? _c(
                      "button",
                      {
                        staticClass: "buttonCopy2",
                        staticStyle: { position: "relative", left: "21px" },
                        attrs: {
                          type: "button",
                          "aria-label": "Выбор с помощью фильтров"
                        },
                        on: { click: _vm.goToChess }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticStyle: { position: "absolute", left: "20px" },
                            attrs: {
                              fill: "#fff",
                              width: "19",
                              height: "24",
                              version: "1.1",
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 297 297",
                              "xmlns:xlink": "http://www.w3.org/1999/xlink",
                              "enable-background": "new 0 0 297 297"
                            }
                          },
                          [
                            _c("g", [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M148.51,117.216c32.317,0,58.608-26.291,58.608-58.608S180.827,0,148.51,0c-32.317,0-58.608,26.291-58.608,58.608   S116.193,117.216,148.51,117.216z"
                                }
                              }),
                              _vm._v(" "),
                              _c("path", {
                                attrs: {
                                  d:
                                    "m227.154,145.618c-0.025-0.008-0.073-0.026-0.098-0.032-7.631-1.864-30.999-5.133-30.999-5.133-2.638-0.812-5.457,0.585-6.406,3.188l-35.174,96.509c-2.029,5.567-9.903,5.567-11.932,0l-35.174-96.509c-0.766-2.102-2.75-3.42-4.876-3.42-0.504,0-24.531,3.369-32.53,5.358-21.858,5.435-35.645,26.929-35.645,49.329v80.302c0,12.034 9.756,21.79 21.79,21.79h184.782c12.034,0 21.79-9.756 21.79-21.79v-80.569c-0.001-22.303-14.328-42.096-35.528-49.023z"
                                }
                              }),
                              _vm._v(" "),
                              _c("path", {
                                attrs: {
                                  d:
                                    "m161.775,138.613c-1.404-1.53-3.456-2.299-5.532-2.299h-15.485c-2.076,0-4.129,0.77-5.532,2.299-2.173,2.368-2.489,5.789-0.946,8.462l8.278,12.479-3.875,32.69 7.631,20.3c0.744,2.042 3.631,2.042 4.375,0l7.631-20.3-3.875-32.69 8.278-12.479c1.541-2.673 1.225-6.094-0.948-8.462z"
                                }
                              })
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "buttonCopy2span" }, [
                          _vm._v("К фильтрам")
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isMobile()
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          position: "relative",
                          left: "53px",
                          cursor: "pointer",
                          width: "200px",
                          "font-size": "9px"
                        },
                        attrs: {
                          type: "button",
                          "aria-label": "Выбор с помощью фильтров"
                        },
                        on: { click: _vm.goToChess }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "buttonCopy2span",
                            staticStyle: { "text-decoration": "underline" }
                          },
                          [_vm._v("К фильтрам")]
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.modal_14,
                        expression: "modal_14"
                      }
                    ],
                    staticClass: "c-modal"
                  },
                  [_c("span", [_vm._v("modal_14")])]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.modal_14,
                        expression: "modal_14"
                      }
                    ],
                    staticClass: "bg",
                    attrs: { id: "switcher_window_dialog" },
                    on: { click: _vm.setModalDialogBySelectFaltWhiteBox }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "close",
                        on: { click: _vm.setModalDialogBySelectFaltWhiteBox }
                      },
                      [
                        _c("span", {
                          on: { click: _vm.setModalDialogBySelectFaltWhiteBox }
                        }),
                        _vm._v(" "),
                        _c("span", {
                          on: { click: _vm.setModalDialogBySelectFaltWhiteBox }
                        }),
                        _vm._v(" "),
                        _c("span", {
                          on: { click: _vm.setModalDialogBySelectFaltWhiteBox }
                        }),
                        _vm._v(" "),
                        _c("span", {
                          on: { click: _vm.setModalDialogBySelectFaltWhiteBox }
                        }),
                        _vm._v(" "),
                        _c(
                          "svg",
                          {
                            staticClass: "circle",
                            attrs: { viewBox: "0 0 36 36" },
                            on: {
                              click: _vm.setModalDialogBySelectFaltWhiteBox
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-dasharray": "100, 100",
                                d:
                                  "M18 2.0845\n        a 15.9155 15.9155 0 0 1 0 31.831\n        a 15.9155 15.9155 0 0 1 0 -31.831"
                              },
                              on: {
                                click: _vm.setModalDialogBySelectFaltWhiteBox
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "favwindowFlatParent2selCards" },
                      _vm._l(_vm.boardUniq, function(idhash, idxf) {
                        return _vm.isAreaInList(idxf)
                          ? _c(
                              "div",
                              {
                                staticClass: "favwindowFlat",
                                staticStyle: { width: "166px" }
                              },
                              [
                                _c("chose-card-wb", {
                                  key: idhash,
                                  attrs: {
                                    prices_finish: _vm.prices_finish ?? [],
                                    property: _vm.propertyGet(idhash)
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      }),
                      0
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.modal_12,
                        expression: "modal_12"
                      }
                    ],
                    staticClass: "c-modal"
                  },
                  [_c("span", [_vm._v("modal_12")])]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.modal_12,
                        expression: "modal_12"
                      }
                    ],
                    staticClass: "bg",
                    attrs: { id: "switcher_window_dialog" },
                    on: { click: _vm.setModalDialogBySelectFalt }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "close",
                        on: { click: _vm.setModalDialogBySelectFalt }
                      },
                      [
                        _c("span", {
                          on: { click: _vm.setModalDialogBySelectFalt }
                        }),
                        _vm._v(" "),
                        _c("span", {
                          on: { click: _vm.setModalDialogBySelectFalt }
                        }),
                        _vm._v(" "),
                        _c("span", {
                          on: { click: _vm.setModalDialogBySelectFalt }
                        }),
                        _vm._v(" "),
                        _c("span", {
                          on: { click: _vm.setModalDialogBySelectFalt }
                        }),
                        _vm._v(" "),
                        _c(
                          "svg",
                          {
                            staticClass: "circle",
                            attrs: { viewBox: "0 0 36 36" },
                            on: { click: _vm.setModalDialogBySelectFalt }
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-dasharray": "100, 100",
                                d:
                                  "M18 2.0845\n        a 15.9155 15.9155 0 0 1 0 31.831\n        a 15.9155 15.9155 0 0 1 0 -31.831"
                              },
                              on: { click: _vm.setModalDialogBySelectFalt }
                            })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "favwindowFlatParent2selCards" },
                      _vm._l(_vm.boardUniq, function(idhash, idxf) {
                        return _c(
                          "div",
                          {
                            staticClass: "favwindowFlat",
                            staticStyle: { width: "166px" }
                          },
                          [
                            _c("chose-card", {
                              key: idhash,
                              attrs: { property: _vm.propertyGet(idhash) }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.modal_2,
                        expression: "modal_2"
                      }
                    ],
                    staticClass: "c-modal"
                  },
                  [_c("span", [_vm._v("modal_2")])]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.modal_2,
                        expression: "modal_2"
                      }
                    ],
                    staticClass: "bg",
                    attrs: { id: "bg_fav_window_dialog" },
                    on: { click: _vm.setModalDialog }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "close",
                        on: { click: _vm.setModalDialog }
                      },
                      [
                        _c("span", { on: { click: _vm.setModalDialog } }),
                        _vm._v(" "),
                        _c("span", { on: { click: _vm.setModalDialog } }),
                        _vm._v(" "),
                        _c("span", { on: { click: _vm.setModalDialog } }),
                        _vm._v(" "),
                        _c("span", { on: { click: _vm.setModalDialog } }),
                        _vm._v(" "),
                        _c(
                          "svg",
                          {
                            staticClass: "circle",
                            attrs: { viewBox: "0 0 36 36" },
                            on: { click: _vm.setModalDialog }
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-dasharray": "100, 100",
                                d:
                                  "M18 2.0845\n        a 15.9155 15.9155 0 0 1 0 31.831\n        a 15.9155 15.9155 0 0 1 0 -31.831"
                              },
                              on: { click: _vm.setModalDialog }
                            })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "favwindowFlatParent" }, [
                      _c(
                        "div",
                        {
                          staticClass: "favwindowFlat",
                          staticStyle: { width: "500px", height: "550px" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "dialogAsc-mask",
                              attrs: { onclick: "event.stopPropagation();" }
                            },
                            [
                              _c("div", { staticClass: "dialogAsc-wrapper" }, [
                                _c(
                                  "div",
                                  { staticClass: "dialogAsc-container" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "dialogAsc-header" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              float: "right",
                                              right: "0px",
                                              width: "45px",
                                              height: "45px"
                                            },
                                            attrs: {
                                              "data-v-c4a012b6": "",
                                              id: "18iconsChat",
                                              onclick:
                                                "window.open('https://t.me/DomOtel_Tmn_bot', '_blank').focus();"
                                            }
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticStyle: {
                                                  cursor: "pointer"
                                                },
                                                attrs: {
                                                  "data-v-c4a012b6": "",
                                                  id: "chat_telegram",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  "xmlns:xlink":
                                                    "http://www.w3.org/1999/xlink",
                                                  viewBox: "0 0 240.1 240.1",
                                                  height: "45px",
                                                  width: "45px"
                                                }
                                              },
                                              [
                                                _c(
                                                  "linearGradient",
                                                  {
                                                    attrs: {
                                                      "data-v-c4a012b6": "",
                                                      id: "Oval_1_",
                                                      gradientUnits:
                                                        "userSpaceOnUse",
                                                      x1: "-838.041",
                                                      y1: "660.581",
                                                      x2: "-838.041",
                                                      y2: "660.3427",
                                                      gradientTransform:
                                                        "matrix(1000 0 0 -1000 838161 660581)"
                                                    }
                                                  },
                                                  [
                                                    _c("stop", {
                                                      staticStyle: {
                                                        "stop-color":
                                                          "rgb(42, 171, 238)"
                                                      },
                                                      attrs: {
                                                        "data-v-c4a012b6": "",
                                                        offset: "0"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c("stop", {
                                                      staticStyle: {
                                                        "stop-color":
                                                          "rgb(34, 158, 217)"
                                                      },
                                                      attrs: {
                                                        "data-v-c4a012b6": "",
                                                        offset: "1"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("circle", {
                                                  attrs: {
                                                    "data-v-c4a012b6": "",
                                                    "fill-rule": "evenodd",
                                                    "clip-rule": "evenodd",
                                                    fill: "url(#Oval_1_)",
                                                    cx: "120.1",
                                                    cy: "120.1",
                                                    r: "120.1"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("path", {
                                                  attrs: {
                                                    "data-v-c4a012b6": "",
                                                    "fill-rule": "evenodd",
                                                    "clip-rule": "evenodd",
                                                    fill: "#FFFFFF",
                                                    d:
                                                      "M54.3,118.8c35-15.2,58.3-25.3,70-30.2 c33.3-13.9,40.3-16.3,44.8-16.4c1,0,3.2,0.2,4.7,1.4c1.2,1,1.5,2.3,1.7,3.3s0.4,3.1,0.2,4.7c-1.8,19-9.6,65.1-13.6,86.3c-1.7,9-5,12-8.2,12.3c-7,0.6-12.3-4.6-19-9c-10.6-6.9-16.5-11.2-26.8-18c-11.9-7.8-4.2-12.1,2.6-19.1c1.8-1.8,32.5-29.8,33.1-32.3 c0.1-0.3,0.1-1.5-0.6-2.1c-0.7-0.6-1.7-0.4-2.5-0.2c-1.1,0.2-17.9,11.4-50.6,33.5c-4.8,3.3-9.1,4.9-13,4.8c-4.3-0.1-12.5-2.4-18.7-4.4c-7.5-2.4-13.5-3.7-13-7.9C45.7,123.3,48.7,121.1,54.3,118.8z"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "h6",
                                          { attrs: { name: "header" } },
                                          [_vm._v("Заполните")]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "dialogAsc-body" },
                                      [
                                        _c(
                                          "form",
                                          {
                                            attrs: {
                                              action: _vm.FORM_ENDPOINT,
                                              method: "POST"
                                            },
                                            on: { submit: _vm.handleSubmit }
                                          },
                                          [
                                            _c("div", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.name1,
                                                    expression: "name1"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "Имя",
                                                  name: "name",
                                                  required: ""
                                                },
                                                domProps: { value: _vm.name1 },
                                                on: {
                                                  input: function($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.name1 =
                                                      $event.target.value
                                                  }
                                                }
                                              })
                                            ]),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.phone1,
                                                    expression: "phone1"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "phone",
                                                  placeholder: "Номер телефона",
                                                  name: "phone",
                                                  required: ""
                                                },
                                                domProps: { value: _vm.phone1 },
                                                on: {
                                                  input: function($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.phone1 =
                                                      $event.target.value
                                                  }
                                                }
                                              })
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "h6",
                                              { attrs: { name: "body" } },
                                              [_vm._v("Ваш вопрос")]
                                            ),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c("textarea", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.message1,
                                                    expression: "message1"
                                                  }
                                                ],
                                                attrs: {
                                                  id: "yourMessage",
                                                  placeholder: "Ваше сообщение",
                                                  name: "message",
                                                  required: ""
                                                },
                                                domProps: {
                                                  value: _vm.message1
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.message1 =
                                                      $event.target.value
                                                  }
                                                }
                                              })
                                            ]),
                                            _vm._v(" "),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "buttonCopy2",
                                                  on: {
                                                    click: _vm.sendMessage1
                                                  }
                                                },
                                                [_vm._v("Отправить сообщение")]
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.submitted
                                          ? _c("div", [
                                              _c("h2", [_vm._v("Спасибо!")]),
                                              _vm._v(" "),
                                              _c("div", [
                                                _vm._v(
                                                  "Мы ответим, как только рассмотрим ваше обращение."
                                                )
                                              ])
                                            ])
                                          : _vm._e()
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "dialogAsc-footer" },
                                      [
                                        _vm._t("footer", function() {
                                          return [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "dialogAsc-default-button",
                                                on: {
                                                  click: _vm.setModalDialog
                                                }
                                              },
                                              [_vm._v("Закрыть")]
                                            )
                                          ]
                                        })
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    ])
                  ]
                )
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }