var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "b-page d-flex flex-column full-height overflow-hidden pos-r"
    },
    [
      _vm.isManagerMode
        ? _c(
            "header",
            {
              staticClass: "flex-grow-0 pr-4 pos-rel",
              class: {
                "py-12": _vm.mq.mdAndUp,
                "py-4": _vm.mq.mdAndDown,
                "pl-10": _vm.mq.mdAndUp,
                "pl-6": _vm.mq.mdAndDown
              }
            },
            [
              _c("img", {
                staticStyle: {
                  cursor: "pointer",
                  position: "absolute",
                  left: "20px",
                  top: "10px"
                },
                attrs: {
                  src:
                    "https://xn--d1acscjb2a6f.xn--p1ai/storage/6748/0031/674822eb0aa21287fee535ac328e1d906bc02c09.svg",
                  alt: ""
                },
                on: { click: _vm.gotoHome }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex align-center" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center flex-grow-1 pr-5" },
                  [
                    _vm.mq.mdAndDown && _vm.modelDistrict
                      ? _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.$modal.show("filters")
                              }
                            }
                          },
                          [_c("i", { staticClass: "e-icon is-menu" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.mq.mdAndDown && _vm.modelDistrict
                      ? _c(
                          "div",
                          {
                            staticClass: "ml-6 text-subtitle-2 font-weight-500"
                          },
                          [_vm._v(_vm._s(_vm.modelDistrict.name))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.mq.mdAndUp
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-center flex-wrap align-center-inner",
                            staticStyle: {
                              "justify-content": "center",
                              width: "100%",
                              padding: "22px"
                            }
                          },
                          [
                            _c("div", { staticClass: "ml-10" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-body-2 has-opacity-65 mb-2 red-color-domOtel"
                                },
                                [_vm._v("Кол-во комнат")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "e-checkboxes-group py-2" },
                                _vm._l(_vm.allTypes, function(type, ind) {
                                  return _c(
                                    "div",
                                    {
                                      key: `type${ind}`,
                                      staticClass: "e-checkbox-btn"
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.filters.byType,
                                            expression: "filters.byType"
                                          }
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          name: "type",
                                          id: `type${ind}`
                                        },
                                        domProps: {
                                          value: type,
                                          checked: Array.isArray(
                                            _vm.filters.byType
                                          )
                                            ? _vm._i(_vm.filters.byType, type) >
                                              -1
                                            : _vm.filters.byType
                                        },
                                        on: {
                                          change: [
                                            function($event) {
                                              var $$a = _vm.filters.byType,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = type,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.filters,
                                                      "byType",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.filters,
                                                      "byType",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.filters,
                                                  "byType",
                                                  $$c
                                                )
                                              }
                                            },
                                            _vm.onChangeFilter
                                          ]
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { attrs: { for: `type${ind}` } },
                                        [_vm._v(_vm._s(_vm.getName(type)))]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]),
                            _vm._v(" "),
                            !_vm.isLoading && _vm.floorsCount > 1
                              ? _c(
                                  "div",
                                  { staticClass: "ml-15 mr-20 pr-10 pb-8" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-body-2 has-opacity-65 red-color-domOtel"
                                      },
                                      [_vm._v("Этаж")]
                                    ),
                                    _vm._v(" "),
                                    _c("vue-slider", {
                                      attrs: {
                                        width: _vm.sliderWidth,
                                        min: 1,
                                        max: _vm.floorsCount,
                                        tooltip: "always",
                                        "tooltip-placement": "bottom",
                                        lazy: "",
                                        contained: ""
                                      },
                                      on: { change: _vm.onChangeFilter },
                                      model: {
                                        value: _vm.filters.byFloor,
                                        callback: function($$v) {
                                          _vm.$set(_vm.filters, "byFloor", $$v)
                                        },
                                        expression: "filters.byFloor"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isLoading && _vm.maxPrice > _vm.minPrice
                              ? _c(
                                  "div",
                                  { staticClass: "mr-20 pr-10 pb-8" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-body-2 has-opacity-65 red-color-domOtel"
                                      },
                                      [_vm._v("Стоимость, ₽")]
                                    ),
                                    _vm._v(" "),
                                    _c("vue-slider", {
                                      attrs: {
                                        width: _vm.sliderWidth,
                                        min: _vm.minPrice,
                                        max: _vm.maxPrice,
                                        tooltip: "always",
                                        "tooltip-placement": "bottom",
                                        "tooltip-formatter": _vm.priceFormatter,
                                        lazy: "",
                                        contained: ""
                                      },
                                      on: { change: _vm.onChangeFilter },
                                      model: {
                                        value: _vm.filters.byPrice,
                                        callback: function($$v) {
                                          _vm.$set(_vm.filters, "byPrice", $$v)
                                        },
                                        expression: "filters.byPrice"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isLoading && _vm.maxArea > _vm.minArea
                              ? _c(
                                  "div",
                                  { staticClass: "pb-8 mr-10" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-body-2 has-opacity-65 red-color-domOtel"
                                      },
                                      [_vm._v("Площадь, м²")]
                                    ),
                                    _vm._v(" "),
                                    _c("vue-slider", {
                                      attrs: {
                                        width: _vm.sliderWidth,
                                        min: _vm.minArea,
                                        max: _vm.maxArea,
                                        tooltip: "always",
                                        "tooltip-placement": "bottom",
                                        lazy: "",
                                        contained: ""
                                      },
                                      on: { change: _vm.onChangeFilter },
                                      model: {
                                        value: _vm.filters.byArea,
                                        callback: function($$v) {
                                          _vm.$set(_vm.filters, "byArea", $$v)
                                        },
                                        expression: "filters.byArea"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "text-body-2 red-color-domOtel",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.setFilter(true)
                                  }
                                }
                              },
                              [_vm._v("очистить")]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.mq.mdAndUp
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "select ml-auto flex-shrink-0 red-color-domOtel",
                            staticStyle: { width: "100px" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "e-select text-body-1 red-color-domOtel"
                              },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.view,
                                        expression: "view"
                                      }
                                    ],
                                    staticClass:
                                      "e-select text-body-1 red-color-domOtel",
                                    attrs: { name: "district", id: "view" },
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.view = $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "option",
                                      { attrs: { value: "tilePlus" } },
                                      [_vm._v("Плитка+")]
                                    ),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "tile" } }, [
                                      _vm._v("Компактная")
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _vm.isWidget
                  ? _c(
                      "a",
                      {
                        staticClass: "crm4dev-close",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onCloseWidget.apply(null, arguments)
                          }
                        }
                      },
                      [_c("i")]
                    )
                  : _vm._e()
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fadeHeight", mode: "out-in" } }, [
        !_vm.isLoading
          ? _c(
              "div",
              {
                staticClass: "maincontent_parent",
                attrs: { id: "maincontent_parent1" }
              },
              [
                _c(
                  "main",
                  {
                    staticClass: "d-flex flex-grow-1 pos-rel maincontent",
                    attrs: { id: "main" }
                  },
                  [
                    _c("div", {
                      staticStyle: {
                        width: "0px",
                        height: "0xp",
                        "max-height": "920px",
                        position: "absolute",
                        "z-index": "214"
                      },
                      attrs: { id: "closeArea" },
                      on: { click: _vm.checkHidePanel }
                    }),
                    _vm._v(" "),
                    !_vm.isLoading
                      ? _c(
                          "div",
                          {
                            staticClass: "content flex-grow-1 overflow-x-auto"
                          },
                          [
                            _c(
                              "section",
                              {
                                staticClass: "py-10 c-building text-body-2",
                                class: { "is-simple": _vm.view === "tile" },
                                attrs: { id: "tiles" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "tableWithFloor",
                                    attrs: { id: "idTableWithFloor" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c("div", [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.modal_3,
                                                  expression: "modal_3"
                                                }
                                              ],
                                              staticClass: "c-modal"
                                            },
                                            [_c("span", [_vm._v("modal_3")])]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.modal_3,
                                                  expression: "modal_3"
                                                }
                                              ],
                                              staticClass: "bg",
                                              attrs: { id: "bg_fav_window" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "close",
                                                  attrs: {
                                                    id: "closeFavWindow"
                                                  },
                                                  on: { click: _vm.setModal }
                                                },
                                                [
                                                  _c("span"),
                                                  _vm._v(" "),
                                                  _c("span"),
                                                  _vm._v(" "),
                                                  _c("span"),
                                                  _vm._v(" "),
                                                  _c("span"),
                                                  _vm._v(" "),
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass: "circle",
                                                      attrs: {
                                                        viewBox: "0 0 36 36"
                                                      }
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "stroke-dasharray":
                                                            "100, 100",
                                                          d:
                                                            "M18 2.0845\n        a 15.9155 15.9155 0 0 1 0 31.831\n        a 15.9155 15.9155 0 0 1 0 -31.831"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              !_vm.favCoociesNotEmpty()
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                        "text-align": "center",
                                                        "padding-top": "200px"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "h1",
                                                        {
                                                          staticStyle: {
                                                            color: "#fff"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "вы ничего не добавили в избранное"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "favwindowFlatParent"
                                                },
                                                _vm._l(
                                                  _vm.favCoocies(),
                                                  function(idhash, idxf) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "favwindowFlat"
                                                      },
                                                      [
                                                        _c(
                                                          "apartment-card-fav",
                                                          {
                                                            key: idhash,
                                                            attrs: {
                                                              property: _vm.propertyGet(
                                                                idhash
                                                              )
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.reverseKeys(_vm.floorsCount),
                                          function(floor, idxfloor) {
                                            return _vm.isManagerMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "c-building__floor"
                                                  },
                                                  [
                                                    (floor + 1) % 2 == 0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            key: `floor-${floor +
                                                              2}`,
                                                            staticClass:
                                                              "nowInSale floorViewSection1",
                                                            attrs: {
                                                              id: `floorView-${floor +
                                                                2}`
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "buttonFloor"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    floor + 2
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._l(
                                                              _vm.board,
                                                              function(
                                                                section,
                                                                sectionId
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: sectionId
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "c-building__section"
                                                                      },
                                                                      _vm._l(
                                                                        section
                                                                          .propertiesOnFloor[
                                                                          `floor_${floor +
                                                                            2}`
                                                                        ],
                                                                        function(
                                                                          property
                                                                        ) {
                                                                          return _c(
                                                                            "apartment-card",
                                                                            {
                                                                              key:
                                                                                property.id,
                                                                              attrs: {
                                                                                property: property
                                                                              }
                                                                            }
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    (floor - 1) % 2 == 0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            key: `floor-${floor +
                                                              1}`,
                                                            staticClass:
                                                              "nowInSale floorViewSection2",
                                                            attrs: {
                                                              id: `floorView-${floor +
                                                                1}`
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "buttonFloor"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    floor + 1
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._l(
                                                              _vm.board,
                                                              function(
                                                                section,
                                                                sectionId
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: sectionId
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "c-building__section"
                                                                      },
                                                                      _vm._l(
                                                                        section
                                                                          .propertiesOnFloor[
                                                                          `floor_${floor +
                                                                            1}`
                                                                        ],
                                                                        function(
                                                                          property
                                                                        ) {
                                                                          return _c(
                                                                            "apartment-card",
                                                                            {
                                                                              key:
                                                                                property.id,
                                                                              attrs: {
                                                                                property: property
                                                                              }
                                                                            }
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              : _vm._e()
                                          }
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.reverseKeys(_vm.floorsCount),
                                          function(floor, idxfloor) {
                                            return !_vm.isManagerMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "c-building__floor just_building__floor"
                                                  },
                                                  [
                                                    (floor + 1) % 2 == 0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            key: `floor-${floor +
                                                              2}`,
                                                            staticClass:
                                                              "floorViewSection1",
                                                            class: {
                                                              nowInSale: _vm.hasChildWithClass(
                                                                floor + 2,
                                                                "c-building__flat-type"
                                                              )
                                                            },
                                                            attrs: {
                                                              id: `floorView-${floor +
                                                                2}`
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "buttonFloor",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.floorClick(
                                                                      floor + 2,
                                                                      "c-building__flat-type"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    floor + 2
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._l(
                                                              _vm.board,
                                                              function(
                                                                section,
                                                                sectionId
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: sectionId,
                                                                    staticClass:
                                                                      "disNone"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "c-building__section"
                                                                      },
                                                                      _vm._l(
                                                                        section
                                                                          .propertiesOnFloor[
                                                                          `floor_${floor +
                                                                            2}`
                                                                        ],
                                                                        function(
                                                                          property
                                                                        ) {
                                                                          return _c(
                                                                            "apartment-card",
                                                                            {
                                                                              key:
                                                                                property.id,
                                                                              attrs: {
                                                                                property: property
                                                                              }
                                                                            }
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    (floor - 1) % 2 == 0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            key: `floor-${floor +
                                                              1}`,
                                                            staticClass:
                                                              "floorViewSection2",
                                                            class: {
                                                              nowInSale: _vm.hasChildWithClass(
                                                                floor + 1,
                                                                "c-building__flat-type"
                                                              )
                                                            },
                                                            attrs: {
                                                              id: `floorView-${floor +
                                                                1}`
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "buttonFloor",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.floorClick(
                                                                      floor + 1,
                                                                      "c-building__flat-type"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    floor + 1
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._l(
                                                              _vm.board,
                                                              function(
                                                                section,
                                                                sectionId
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: sectionId,
                                                                    staticClass:
                                                                      "disNone"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "c-building__section"
                                                                      },
                                                                      _vm._l(
                                                                        section
                                                                          .propertiesOnFloor[
                                                                          `floor_${floor +
                                                                            1}`
                                                                        ],
                                                                        function(
                                                                          property
                                                                        ) {
                                                                          return _c(
                                                                            "apartment-card",
                                                                            {
                                                                              key:
                                                                                property.id,
                                                                              attrs: {
                                                                                property: property
                                                                              }
                                                                            }
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              : _vm._e()
                                          }
                                        )
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "tittleForBarFloor",
                                        staticStyle: { display: "none" },
                                        attrs: { id: "idTittleForBarFloor" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "#000",
                                              position: "absolute",
                                              "font-size": "9px",
                                              left: "10px",
                                              top: "14px"
                                            }
                                          },
                                          [_vm._v("На этаже")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "svg",
                                          {
                                            staticStyle: {
                                              position: "absolute",
                                              left: "12px",
                                              top: "6px",
                                              "/*pointer-events": "auto"
                                            },
                                            attrs: {
                                              "data-v-7e7f006c": "",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              version: "1.0",
                                              width: "40",
                                              height: "140",
                                              viewBox:
                                                "0 0 283.000000 569.000000",
                                              preserveAspectRatio:
                                                "xMidYMid meet"
                                            }
                                          },
                                          [
                                            _c(
                                              "g",
                                              {
                                                attrs: {
                                                  "data-v-7e7f006c": "",
                                                  transform:
                                                    "translate(0.000000,569.000000) scale(0.100000,-0.100000)",
                                                  fill: "#727272",
                                                  stroke: "none"
                                                }
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    "data-v-7e7f006c": "",
                                                    d:
                                                      "M79 5673 l-66 -4 -7 -52 c-8 -68 -8 -5476 0 -5544 l7 -52 71 -5 c39 -3 673 -8 1409 -11 l1337 -6 -2 2830 c-2 1556 -7 2833 -11 2838 -8 8 -2601 13 -2738 6z m2621 -2828 l0 -2705 -395 0 -395 0 0 323 c-1 386 -8 483 -44 560 -53 112 -160 214 -282 269 -42 19 -67 22 -170 23 l-121 0 -87 -44 c-115 -58 -184 -127 -241 -240 l-43 -86 -7 -402 -7 -403 -389 0 -389 0 0 2705 0 2705 1285 0 1285 0 0 -2705z m-1159 -1684 c74 -28 149 -99 194 -183 l37 -68 5 -385 6 -385 -366 0 -367 0 0 318 c0 342 9 472 35 523 10 18 38 54 63 81 112 120 248 154 393 99z"
                                                  }
                                                }),
                                                _c("path", {
                                                  attrs: {
                                                    "data-v-7e7f006c": "",
                                                    d:
                                                      "M500 5191 c-35 -10 -39 -42 -39 -342 -1 -284 4 -345 27 -354 9 -3 159 -9 333 -12 l317 -6 18 34 c17 31 19 60 19 314 0 316 -4 351 -41 365 -29 11 -595 12 -634 1z m530 -346 l0 -225 -220 0 -220 0 0 225 0 225 220 0 220 0 0 -225z"
                                                  }
                                                }),
                                                _c("path", {
                                                  attrs: {
                                                    "data-v-7e7f006c": "",
                                                    d:
                                                      "M1697 5193 c-42 -11 -47 -47 -47 -340 0 -308 6 -349 48 -360 33 -10 587 -9 624 0 15 4 30 17 34 30 4 12 8 160 8 330 0 278 -2 309 -17 325 -16 15 -49 17 -325 19 -169 0 -315 -1 -325 -4z m533 -348 l0 -225 -225 0 -225 0 0 183 c0 220 5 254 34 260 11 2 110 5 219 6 l197 1 0 -225z"
                                                  }
                                                }),
                                                _c("path", {
                                                  attrs: {
                                                    "data-v-7e7f006c": "",
                                                    d:
                                                      "M505 4262 c-23 -6 -31 -14 -36 -37 -12 -53 -10 -600 2 -633 10 -29 13 -31 84 -37 41 -4 187 -6 325 -4 252 4 252 4 271 28 19 23 20 40 17 342 -2 244 -6 321 -16 331 -10 10 -83 13 -315 15 -166 1 -315 -2 -332 -5z m525 -352 l0 -230 -220 0 -220 0 0 230 0 230 220 0 220 0 0 -230z"
                                                  }
                                                }),
                                                _c("path", {
                                                  attrs: {
                                                    "data-v-7e7f006c": "",
                                                    d:
                                                      "M1693 4260 c-36 -15 -43 -68 -43 -350 0 -293 6 -335 48 -351 17 -7 138 -9 329 -7 274 3 304 5 320 21 17 16 18 44 18 337 0 293 -1 321 -18 337 -16 16 -45 18 -325 20 -172 2 -317 -1 -329 -7z m537 -351 l0 -231 -217 7 c-120 4 -220 9 -222 11 -2 2 -6 102 -9 224 l-5 220 227 0 226 0 0 -231z"
                                                  }
                                                }),
                                                _c("path", {
                                                  attrs: {
                                                    "data-v-7e7f006c": "",
                                                    d:
                                                      "M588 3331 c-58 -3 -110 -10 -115 -14 -4 -5 -8 -157 -8 -337 0 -256 3 -332 13 -342 10 -10 83 -14 310 -16 163 -2 312 0 330 3 23 4 36 14 44 33 7 18 12 129 12 322 1 265 -1 298 -17 328 l-18 32 -222 -2 c-122 -1 -270 -4 -329 -7z m442 -356 l0 -225 -220 0 -220 0 0 225 0 225 220 0 220 0 0 -225z"
                                                  }
                                                }),
                                                _c("path", {
                                                  attrs: {
                                                    "data-v-7e7f006c": "",
                                                    d:
                                                      "M1775 3334 c-95 -6 -103 -10 -114 -52 -12 -40 -15 -570 -5 -610 13 -48 41 -52 353 -52 160 0 301 4 315 10 18 6 27 20 33 47 9 48 9 590 -1 620 -4 11 -13 24 -22 27 -18 7 -478 15 -559 10z m455 -360 l0 -227 -216 5 c-118 3 -218 8 -221 11 -3 3 -8 103 -11 221 l-5 216 227 0 226 0 0 -226z"
                                                  }
                                                }),
                                                _c("path", {
                                                  attrs: {
                                                    "data-v-7e7f006c": "",
                                                    d:
                                                      "M488 2393 c-13 -3 -18 -22 -23 -88 -9 -108 -2 -581 8 -592 10 -9 596 -17 644 -8 56 10 58 21 58 335 0 157 -4 298 -8 313 -4 16 -18 32 -33 37 -25 10 -611 12 -646 3z m542 -343 l0 -220 -220 0 -220 0 0 220 0 220 220 0 220 0 0 -220z"
                                                  }
                                                }),
                                                _c("path", {
                                                  attrs: {
                                                    "data-v-7e7f006c": "",
                                                    d:
                                                      "M1675 2386 c-19 -14 -20 -26 -20 -336 0 -310 1 -322 20 -336 28 -20 661 -16 674 4 13 21 22 318 14 491 -3 88 -10 166 -14 173 -13 20 -646 24 -674 4z m555 -336 l0 -220 -225 0 -225 0 0 220 0 220 225 0 225 0 0 -220z"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.mq.mdAndUp
                      ? _c("apartment-details", {
                          on: {
                            changemode: function($event) {
                              _vm.modal_3 = $event
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      false
        ? _c("footer", { class: { "pa-2": _vm.mq.mdAndDown } }, [
            _c(
              "div",
              {
                staticClass: "l-row",
                class: { "flex-column-reverse": _vm.mq.mdAndDown }
              },
              [
                _c(
                  "div",
                  { staticClass: "l-col py-0 text-body-2 has-text-primary" },
                  [_vm._v("© CRM4DEV " + _vm._s(new Date().getFullYear()))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "l-col py-0" }, [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex text-body-2",
                      class: { "justify-center": _vm.mq.mdAndUp }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "legend",
                          class: { "flex-wrap": _vm.mq.mdAndDown }
                        },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _vm._m(1),
                          _vm._v(" "),
                          _vm._m(2),
                          _vm._v(" "),
                          _vm._m(3)
                        ]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "l-col py-0" })
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("CoolLightBox", {
        attrs: { items: _vm.imageItems, index: _vm.imageIndex },
        on: {
          close: function($event) {
            return _vm.$store.commit("set", {
              path: "imageIndex",
              payload: null
            })
          }
        }
      }),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "filters",
            width: "100%",
            height: "100%",
            adaptive: ""
          }
        },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-column overflow-hidden full-height" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "text-subtitle-2 font-weight-500 py-4 px-5 box-shadow-bottom"
                },
                [_vm._v("Фильтры")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column flex-grow-1 overflow-x-auto px-5 pt-10 pb-20"
                },
                [
                  _c("div", { staticClass: "select full-width" }, [
                    _c("div", { staticClass: "e-select text-body-1" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.modelDistrict,
                              expression: "modelDistrict"
                            }
                          ],
                          attrs: { name: "district", id: "m-district" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.modelDistrict = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        _vm._l(_vm.districts, function(district) {
                          return _c(
                            "option",
                            { key: district.id, domProps: { value: district } },
                            [_vm._v(_vm._s(district.name))]
                          )
                        }),
                        0
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "select full-width mt-5" }, [
                    _c("div", { staticClass: "e-select text-body-1" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.modelObject,
                              expression: "modelObject"
                            }
                          ],
                          attrs: { name: "object", id: "m-object" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.modelObject = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        _vm._l(_vm.objects, function(object) {
                          return _c(
                            "option",
                            { key: object.id, domProps: { value: object } },
                            [_vm._v(_vm._s(object.name))]
                          )
                        }),
                        0
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-8" }, [
                    _c(
                      "div",
                      { staticClass: "text-body-2 has-opacity-65 mb-2" },
                      [_vm._v("Кол-во комнат")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "e-checkboxes-group py-2" },
                      _vm._l(_vm.allTypes, function(type, ind) {
                        return _c(
                          "div",
                          {
                            key: `type${ind}`,
                            staticClass:
                              "e-checkbox-btn is-lg d-flex justify-center"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filters.byType,
                                  expression: "filters.byType"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                name: "type",
                                id: `m-type${ind}`
                              },
                              domProps: {
                                value: type,
                                checked: Array.isArray(_vm.filters.byType)
                                  ? _vm._i(_vm.filters.byType, type) > -1
                                  : _vm.filters.byType
                              },
                              on: {
                                change: [
                                  function($event) {
                                    var $$a = _vm.filters.byType,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = type,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.filters,
                                            "byType",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.filters,
                                            "byType",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.filters, "byType", $$c)
                                    }
                                  },
                                  _vm.onChangeFilter
                                ]
                              }
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: `m-type${ind}` } }, [
                              _vm._v(_vm._s(type))
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ]),
                  _vm._v(" "),
                  !_vm.isLoading && _vm.floorsCount > 1
                    ? _c("div", { staticClass: "mt-8 pb-12" }, [
                        _c(
                          "div",
                          { staticClass: "text-body-2 has-opacity-65" },
                          [_vm._v("Этаж")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center" },
                          [
                            _c("vue-slider", {
                              attrs: {
                                width: "200px",
                                "dot-size": "30",
                                height: "6px",
                                min: 1,
                                max: _vm.floorsCount,
                                tooltip: "always",
                                "tooltip-placement": "bottom",
                                lazy: "",
                                contained: ""
                              },
                              on: { change: _vm.onChangeFilter },
                              model: {
                                value: _vm.filters.byFloor,
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "byFloor", $$v)
                                },
                                expression: "filters.byFloor"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isLoading && _vm.maxPrice > _vm.minPrice
                    ? _c("div", { staticClass: "mt-8 pb-12" }, [
                        _c(
                          "div",
                          { staticClass: "text-body-2 has-opacity-65" },
                          [_vm._v("Стоимость, ₽")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center" },
                          [
                            _c("vue-slider", {
                              attrs: {
                                width: "200px",
                                "dot-size": "30",
                                height: "6px",
                                min: _vm.minPrice,
                                max: _vm.maxPrice,
                                tooltip: "always",
                                "tooltip-placement": "bottom",
                                "tooltip-formatter": _vm.priceFormatter,
                                lazy: "",
                                contained: ""
                              },
                              on: { change: _vm.onChangeFilter },
                              model: {
                                value: _vm.filters.byPrice,
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "byPrice", $$v)
                                },
                                expression: "filters.byPrice"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isLoading && _vm.maxArea > _vm.minArea
                    ? _c("div", { staticClass: "mt-8 pb-12" }, [
                        _c(
                          "div",
                          { staticClass: "text-body-2 has-opacity-65" },
                          [_vm._v("Площадь, м²")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center" },
                          [
                            _c("vue-slider", {
                              attrs: {
                                width: "200px",
                                "dot-size": "30",
                                height: "6px",
                                min: _vm.minArea,
                                max: _vm.maxArea,
                                tooltip: "always",
                                "tooltip-placement": "bottom",
                                lazy: "",
                                contained: ""
                              },
                              on: { change: _vm.onChangeFilter },
                              model: {
                                value: _vm.filters.byArea,
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "byArea", $$v)
                                },
                                expression: "filters.byArea"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-8 text-center" }, [
                    _c(
                      "a",
                      {
                        staticClass: "d-block text-body-1",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.setFilter(true)
                          }
                        }
                      },
                      [_vm._v("очистить")]
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "e-btn is-primary full-width is-tile",
                    on: {
                      click: function($event) {
                        return _vm.$modal.hide("filters")
                      }
                    }
                  },
                  [_vm._v("Показать")]
                )
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: { name: "info", width: "100%", height: "100%", adaptive: "" }
        },
        [_c("apartment-details")],
        1
      ),
      _vm._v(" "),
      !_vm.isManagerMode
        ? _c(
            "modal",
            {
              attrs: {
                name: "modal",
                adaptive: "",
                width: "100%",
                "max-width": 340,
                height: "auto"
              },
              on: { "before-open": _vm.onOpenModal, closed: _vm.onCloseModal }
            },
            [
              !!_vm.sentResult
                ? _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column align-center pa-15 pb-10"
                    },
                    [
                      _vm.sentResult === "success"
                        ? _c(
                            "svg",
                            {
                              staticStyle: { width: "35%", fill: "#02C39E" },
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 496 496"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M248 0C111.033 0 0 111.033 0 248s111.033 248 248 248 248-111.033 248-248C495.841 111.099 384.901.159 248 0zm0 480C119.87 480 16 376.13 16 248S119.87 16 248 16s232 103.87 232 232c-.141 128.072-103.928 231.859-232 232z"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M370.344 158.344L208 320.688l-82.344-82.344a8 8 0 00-11.312 11.312l88 88a8 8 0 0011.312 0l168-168a8 8 0 00-11.312-11.312z"
                                }
                              })
                            ]
                          )
                        : _c(
                            "svg",
                            {
                              staticStyle: { width: "35%", fill: "#DC3545" },
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 496 496"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M248 0C111.033 0 0 111.033 0 248s111.033 248 248 248 248-111.033 248-248C495.841 111.099 384.901.159 248 0zm0 480C119.87 480 16 376.13 16 248S119.87 16 248 16s232 103.87 232 232c-.141 128.072-103.928 231.859-232 232z"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M361.136 134.864a8 8 0 00-11.312 0L248 236.688 146.176 134.864a8 8 0 10-11.312 11.312L236.688 248 134.864 349.824a8 8 0 00-.196 11.312 8 8 0 0011.508 0L248 259.312l101.824 101.824a8 8 0 0011.312-.196 8 8 0 000-11.116L259.312 248l101.824-101.824a8 8 0 000-11.312z"
                                }
                              })
                            ]
                          ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "text-subtitle-2 font-weight-400 mt-15"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.sentResult === "success"
                                  ? "Запрос успешно отправлен"
                                  : "Неудалось отправить запрос"
                              ) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "d-block text-body-2 tt-uppersace has-opacity-65 has-text-primary text-center mt-15",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.$modal.hide("modal")
                            }
                          }
                        },
                        [_vm._v("закрыть")]
                      )
                    ]
                  )
                : _c(
                    "form",
                    {
                      staticClass: "pa-15 pb-10",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.onSendRequest.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "text-h3 mb-15" }, [
                        _vm._v(
                          _vm._s(
                            _vm.requestType == "chessres"
                              ? "Забронировать"
                              : "Задать вопрос"
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-5" }, [
                        _c(
                          "label",
                          {
                            staticClass:
                              "d-block text-body-2 has-opacity-65 mb-2",
                            attrs: { for: "name" }
                          },
                          [_vm._v("Ф.И.О.*")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.contacts.name,
                              expression: "contacts.name"
                            }
                          ],
                          staticClass: "e-input-field full-width",
                          attrs: { type: "text", id: "name", required: "" },
                          domProps: { value: _vm.contacts.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.contacts,
                                "name",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-5" }, [
                        _c(
                          "label",
                          {
                            staticClass:
                              "d-block text-body-2 has-opacity-65 mb-2",
                            attrs: { for: "phone" }
                          },
                          [_vm._v("Номер телефона*")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: "+7 (###) ###-##-##",
                              expression: "'+7 (###) ###-##-##'"
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.contacts.phone,
                              expression: "contacts.phone"
                            }
                          ],
                          staticClass: "e-input-field full-width",
                          attrs: { type: "tel", id: "phone", required: "" },
                          domProps: { value: _vm.contacts.phone },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.contacts,
                                "phone",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-8" }, [
                        _c(
                          "label",
                          {
                            staticClass:
                              "d-block text-body-2 has-opacity-65 mb-2",
                            attrs: { for: "email" }
                          },
                          [_vm._v("Email")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.contacts.email,
                              expression: "contacts.email"
                            }
                          ],
                          staticClass: "e-input-field full-width",
                          attrs: { type: "tel", id: "email" },
                          domProps: { value: _vm.contacts.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.contacts,
                                "email",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "e-btn is-primary full-width mb-5",
                          class: { "is-loading": _vm.isSending },
                          attrs: {
                            disabled:
                              _vm.isSending ||
                              !_vm.contacts.phone ||
                              !_vm.contacts.name
                          },
                          on: { submit: _vm.onSendRequest }
                        },
                        [_vm._v("Отправить")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-body-2 has-opacity-45" }, [
                        _vm._v(
                          "Нажимая «Отправить», Вы соглашаетесь предоставить Вашу информацию на обработку"
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "d-block text-body-2 tt-uppersace has-opacity-65 has-text-primary text-center mt-15",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.$modal.hide("modal")
                            }
                          }
                        },
                        [_vm._v("закрыть")]
                      )
                    ]
                  )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading && !_vm.isManagerMode
        ? _c("div", { staticClass: "loader", class: _vm.loadingClasses }, [
            false
              ? _c("div", [
                  _c("div", { staticClass: "galTitle text-copy4" }, [
                    _vm._v(
                      "\n          Не хотите заниматься отделкой?\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "galTitle text-body-21" }, [
                    _vm._v(
                      "\n          Вы можете приобрести квартиру с готовой отделкой и даже с мебелью. Заезжай и живи. Дизайны ниже нам очень нравятся, надеемся что и вам они будут по душе.\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "main",
                    {
                      staticClass:
                        "dflexContent flex-grow-1 pos-rel maincontentBottom"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "exampleGal" },
                        [
                          _vm._m(4),
                          _vm._v(" "),
                          _c(
                            "photo-collage-wrapper",
                            _vm._b(
                              {
                                attrs: { gapSize: "6px" },
                                on: { itemClick: _vm.itemClickHandlerW }
                              },
                              "photo-collage-wrapper",
                              _vm.collageWtmb,
                              false
                            )
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "exampleGal" },
                        [
                          _vm._m(5),
                          _vm._v(" "),
                          _c(
                            "photo-collage-wrapper",
                            _vm._b(
                              {
                                attrs: { gapSize: "6px" },
                                on: { itemClick: _vm.itemClickHandlerD }
                              },
                              "photo-collage-wrapper",
                              _vm.collageDtmb,
                              false
                            )
                          )
                        ],
                        1
                      )
                    ]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && !_vm.isManagerMode
        ? _c(
            "div",
            {
              staticClass: "mobile__flat__callback",
              on: { click: _vm.gotoHome }
            },
            [
              _c(
                "div",
                {
                  staticClass: "close",
                  staticStyle: { "z-index": "71" },
                  on: { click: _vm.gotoHome }
                },
                [
                  _c("span"),
                  _vm._v(" "),
                  _c("span"),
                  _vm._v(" "),
                  _c("span"),
                  _vm._v(" "),
                  _c("span"),
                  _vm._v(" "),
                  _c(
                    "svg",
                    { staticClass: "circle", attrs: { viewBox: "0 0 36 36" } },
                    [
                      _c("path", {
                        attrs: {
                          "stroke-dasharray": "100, 100",
                          d:
                            "M18 2.0845\n        a 15.9155 15.9155 0 0 1 0 31.831\n        a 15.9155 15.9155 0 0 1 0 -31.831"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isManagerMode
        ? _c("div", { staticClass: "maincontent_parentBottom" }, [
            _c("div", { staticClass: "galTitle text-copy4" }),
            _vm._v(" "),
            _c("div", { staticClass: "galTitle text-copy4" }, [
              _vm._v("\n        Не хотите заниматься отделкой?\n      ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "galTitle text-body-21" }, [
              _vm._v(
                "\n          Вы можете приобрести квартиру с готовой отделкой и даже с мебелью. Заезжай и живи. Дизайны ниже нам очень нравятся, надеемся что и вам они будут по душе.\n      "
              )
            ]),
            _vm._v(" "),
            _c(
              "main",
              {
                staticClass:
                  "dflexContent flex-grow-1 pos-rel maincontentBottom"
              },
              [
                _c(
                  "div",
                  { staticClass: "exampleGal" },
                  [
                    _vm._m(6),
                    _vm._v(" "),
                    _c(
                      "photo-collage-wrapper",
                      _vm._b(
                        {
                          attrs: { gapSize: "6px" },
                          on: { itemClick: _vm.itemClickHandlerW }
                        },
                        "photo-collage-wrapper",
                        _vm.collageWtmb,
                        false
                      )
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "exampleGal" },
                  [
                    _vm._m(7),
                    _vm._v(" "),
                    _c(
                      "photo-collage-wrapper",
                      _vm._b(
                        {
                          attrs: { gapSize: "6px" },
                          on: { itemClick: _vm.itemClickHandlerD }
                        },
                        "photo-collage-wrapper",
                        _vm.collageDtmb,
                        false
                      )
                    )
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.modal_5,
                expression: "modal_5"
              }
            ],
            staticClass: "c-modal"
          },
          [_c("span", [_vm._v("modal_5")])]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.modal_5,
                expression: "modal_5"
              }
            ],
            staticClass: "bg",
            attrs: { id: "bg_fav_window5" }
          },
          [
            _c("div", { staticClass: "close", on: { click: _vm.setModal5 } }, [
              _c("span"),
              _vm._v(" "),
              _c("span"),
              _vm._v(" "),
              _c("span"),
              _vm._v(" "),
              _c("span"),
              _vm._v(" "),
              _c(
                "svg",
                { staticClass: "circle", attrs: { viewBox: "0 0 36 36" } },
                [
                  _c("path", {
                    attrs: {
                      "stroke-dasharray": "100, 100",
                      d:
                        "M18 2.0845\n        a 15.9155 15.9155 0 0 1 0 31.831\n        a 15.9155 15.9155 0 0 1 0 -31.831"
                    }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c("light-box", {
              ref: "lightbox5",
              attrs: {
                media: this.matched_all,
                closable: false,
                showCaption: false,
                showThumbs: false
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      !_vm.isManagerMode
        ? _c("div", { staticClass: "maincontent_selector" }, [
            _c(
              "div",
              {
                staticClass: "galTitle text-copy4",
                staticStyle: { "font-size": "14px" }
              },
              [
                _vm._v(
                  "\n        Хотите посчитать возможную ипотеку? Выберите банк:\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "selector1domrf",
                on: {
                  click: function($event) {
                    return _vm.goToDomRf()
                  }
                }
              },
              [
                _c("img", {
                  staticStyle: { height: "42px" },
                  attrs: {
                    src:
                      "https://xn--h1alcedd.xn--d1aqf.xn--p1ai/dist/img/logo-2-dom-rf.svg",
                    alt: ""
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "selector1",
                class: { opc3: _vm.bank !== "sber" },
                on: {
                  click: function($event) {
                    return _vm.setbank("sber")
                  }
                }
              },
              [
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.bank !== "sber",
                      expression: "bank!=='sber'"
                    }
                  ],
                  staticClass: "shadow1"
                }),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src:
                      "https://xn--d1acscjb2a6f.xn--p1ai/images_custom_1/sber.png",
                    alt: ""
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "selector1",
                class: { opc3: _vm.bank !== "vtb" },
                on: {
                  click: function($event) {
                    return _vm.setbank("vtb")
                  }
                }
              },
              [
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.bank !== "vtb",
                      expression: "bank!=='vtb'"
                    }
                  ],
                  staticClass: "shadow1"
                }),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src:
                      "https://xn--d1acscjb2a6f.xn--p1ai/images_custom_1/vtb.png",
                    alt: ""
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c("br")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.bank == "sber",
              expression: "bank=='sber'"
            }
          ],
          staticClass: "maincontent_claculator"
        },
        [
          _c(
            "iframe",
            {
              staticStyle: {
                overflow: "hidden",
                height: "1300px",
                width: "100%",
                "max-width": "1600px",
                border: "0"
              },
              attrs: {
                src: "https://ipoteka.domclick.ru/calc-reg/calculator.html",
                width: "100%",
                height: "1300px",
                frameBorder: "0",
                loading: "lazy"
              }
            },
            [
              _vm._v(
                "\n      Пожалуйста подождите, калькулятор ипотечных расчетов загружается на ваше устройство\n    "
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.bank == "vtb",
              expression: "bank=='vtb'"
            }
          ],
          staticClass: "maincontent_claculator",
          staticStyle: { "max-width": "1000px", height: "8000px" }
        },
        [
          _c(
            "iframe",
            {
              staticStyle: {
                overflow: "hidden",
                height: "8000px",
                width: "100%",
                "max-width": "1600px",
                border: "0"
              },
              attrs: {
                src: "https://www.kreditnyi-calculator.ru/ipoteka/vtb_24/",
                width: "100%",
                height: "8000px",
                frameBorder: "0",
                loading: "lazy"
              }
            },
            [
              _vm._v(
                "\n      Пожалуйста подождите, калькулятор ипотечных расчетов загружается на ваше устройство\n    "
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "legend__item is-free" }, [
      _c("span"),
      _vm._v("Свободно")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "legend__item is-reserved" }, [
      _c("span"),
      _vm._v("Бронь")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "legend__item is-sold" }, [
      _c("span"),
      _vm._v("Продано")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "legend__item is-disabled" }, [
      _c("span"),
      _vm._v("Недоступно")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "galTitle text-body-21",
        staticStyle: { "margin-left": "15px" }
      },
      [
        _c("div", { staticClass: "text-title3dTour" }, [
          _vm._v(" Светлый «осовременненый» минимализм  ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "galTitle" }, [
          _vm._v(" Откройте фото ниже, или перейдите в "),
          _c(
            "a",
            {
              staticClass: "text-copy3dTour",
              staticStyle: { margin: "0px 5px" },
              attrs: {
                href:
                  "https://kuula.co/share/collection/7XL3J?logo=1&info=1&fs=1&vr=0&thumbs=1",
                target: "_blank"
              }
            },
            [_vm._v("3D ТУР")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "galTitle text-body-21",
        staticStyle: { "margin-left": "15px" }
      },
      [
        _c("div", { staticClass: "text-title3dTour" }, [
          _vm._v(" Темный «оклассиченный» лофт  ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "galTitle" }, [
          _vm._v(" Откройте фото ниже, или перейдите в "),
          _c(
            "a",
            {
              staticClass: "text-copy3dTour",
              staticStyle: { margin: "0px 5px" },
              attrs: {
                href:
                  "https://kuula.co/share/collection/7XLsN?logo=1&info=1&fs=1&vr=0&thumbs=1",
                target: "_blank"
              }
            },
            [_vm._v("3D ТУР")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "galTitle text-body-21",
        staticStyle: { "margin-left": "15px" }
      },
      [
        _c("div", { staticClass: "text-title3dTour" }, [
          _vm._v(" Светлый «осовременненый» минимализм ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "galTitle" }, [
          _vm._v(" Откройте фото ниже, или перейдите в "),
          _c(
            "a",
            {
              staticClass: "galTitle text-copy3dTour",
              staticStyle: { margin: "0px 5px" },
              attrs: {
                href:
                  "https://kuula.co/share/collection/7XL3J?logo=1&info=1&fs=1&vr=0&thumbs=1",
                target: "_blank"
              }
            },
            [_vm._v("3D ТУР")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "galTitle text-body-21",
        staticStyle: { "margin-left": "15px" }
      },
      [
        _c("div", { staticClass: "text-title3dTour" }, [
          _vm._v(" Темный «оклассиченный» лофт ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "galTitle" }, [
          _vm._v(" Откройте фото ниже, или перейдите в "),
          _c(
            "a",
            {
              staticClass: "galTitle text-copy3dTour",
              staticStyle: { margin: "0px 5px" },
              attrs: {
                href:
                  "https://kuula.co/share/collection/7XLsN?logo=1&info=1&fs=1&vr=0&thumbs=1",
                target: "_blank"
              }
            },
            [_vm._v("3D ТУР")]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }